var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "pt-0 mb-3" },
    [
      _c(
        "v-alert",
        {
          attrs: {
            border: "top",
            color: "accent",
            dark: "",
            icon: "mdi-account-check",
            prominent: ""
          }
        },
        [
          _vm._v(
            " " + _vm._s(_vm.$t("applications.indicated-applicant-type")) + " "
          ),
          _c("strong", [_vm._v(_vm._s(_vm.applicantTypeLabel))])
        ]
      ),
      _c(
        "v-card-actions",
        { staticClass: "my-0 py-0" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              staticClass: "mb-3",
              attrs: { color: "accent", small: "" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("change")
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("applications.change-this")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }