<template>
    <v-card class="pt-0 mb-3">
        <v-alert
            border="top"
            color="accent"
            dark
            icon="mdi-account-check"
            prominent
        >
            {{ $t("applications.indicated-applicant-type") }}
            <strong>{{ applicantTypeLabel }}</strong>
        </v-alert>
        <v-card-actions class="my-0 py-0">
            <v-spacer></v-spacer>
            <v-btn
                class="mb-3"
                color="accent"
                small
                @click.prevent="$emit('change')"
                >{{ $t("applications.change-this") }}</v-btn
            >
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "applicant-type-complete",
    components: {},
    props: {
        applicantType: {
            type: String,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        applicantTypeLabel() {
            return this.applicantType == "Applicant"
                ? this.$t("applications.financially-responsible")
                : this.$t("applications.non-financially-responsible");
        },
    },
    watch: {},
    methods: {},
};
</script>

<style scoped>
</style>